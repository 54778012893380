
<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-end">
            <div class="header-contact">
              <ul>
                <li>Welcome to Our store Vastra</li>
                <li>
                  <a (click)="copyToClipboard()">
                    <i class="fa fa-phone contacticon" aria-hidden="true"></i>
                  </a>
                  <a href="https://wa.me/9898561044" target="_blank">
                    <i class="fa fa-whatsapp contacticon" aria-hidden="true"></i>
                  </a>
                  <a href="mailto:example@example.com" target="_blank">
                    <i class="fa fa-envelope contacticon" aria-hidden="true"></i>
                  </a>
                  <a href="https://www.instagram.com/shop.vastra" target="_blank">
                    <i class="fa fa-instagram contacticon" aria-hidden="true"></i>
                  </a>
                </li>
                <span *ngIf="isCopied" class="copy-message">Phone number copied to clipboard</span>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">
              <!-- <app-left-menu></app-left-menu> -->
              <div class="brand-logo">
                <a routerLink="/home/fashion" id="headerlogo">
                  <img [src]="themeLogo" class="img-fluid" alt="logo">
                </a>
              </div>
            </div>
            <div class="menu-right pull-right">
              <div>
               <!--  <app-menu></app-menu> -->
              </div>
              <div>
                <app-settings></app-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  