import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Product } from '../../../shared/classes/product';
import { ProductSlider } from '../../../shared/data/slider';
import { ProductService } from '../../../shared/services/product.service';
import { SharedModule } from '../../../shared/shared.module'; // Import SharedModule

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [CommonModule,SharedModule], // Add SharedModule to the imports array
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss'
})
export class ProductComponent implements OnInit {
  public products: Product[] = [];
  public productCollections: any[] = [];
  public active;


  constructor(public productService: ProductService) {
    debugger;
    this.productService.getProducts.subscribe(response => {
      this.products = response.filter(item => item.type == 'fashion');
      // Get Product Collection
      this.products.filter((item) => {
        item.collection.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      })
    });
  }



  public ProductSliderConfig: any = ProductSlider;

  ngOnInit(): void {
  }
// Collection banner
public collections = [{
  image: 'assets/images/collection/fashion/1.jpg',
  save: 'save 50%',
  title: 'men'
}, {
  image: 'assets/images/collection/fashion/2.jpg',
  save: 'save 50%',
  title: 'women'
}];

 // Product Tab collection
 getCollectionProducts(collection) {
  return this.products.filter((item) => {
    if (item.collection.find(i => i === collection)) {
      return item
    }
  })
}
}
