import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Product } from '../../shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>('https://localhost:44330/api/Product').pipe(
      map(data => data),
      /* catchError(error => {
        console.error('Error fetching products:', error);
        return of([]); // Return an empty array in case of error
      }) */
    );
  }
}
