import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HomeAutoSlider, HomeSlider } from '../../../shared/data/slider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {
  
  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;

  public activeSlideIndex: number = 0;
  public previousSlideIndex: number = -1;
  private intervalId: any;

  constructor() { }

  ngOnInit(): void {
    this.startAutoScroll();
  }

  ngOnDestroy(): void {
    this.stopAutoScroll();
  }

  startAutoScroll(): void {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 2000); // Change slide every 2 seconds
  }

  stopAutoScroll(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  nextSlide(): void {
    /* this.previousSlideIndex = this.activeSlideIndex;
    this.activeSlideIndex = (this.activeSlideIndex + 1) % this.sliders.length; */
  }

  public HomeSliderConfig: any = HomeAutoSlider;

}