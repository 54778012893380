import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {
debugger;
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string
  private cartSubscription: Subscription;
   // Example property to track if the product is in the cart
   //isInCart: boolean = false;
   

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.cartSubscription = this.productService.cartUpdated.subscribe(() => {
      // Update the product's isInCart property
      const productInState = this.productService.getCart().find(p => p.id === this.product.id);
      this.product.isInCart = !!productInState;
    });
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    //this.updateLocalStorage(product);
    const result = this.productService.addToCart(product);
    if (result) {
      this.product.isInCart = true;
      //this.updateLocalStorage(product);
    }
  }

  addToWishlist(product: any) {
    const result = this.productService.addToWishlist(product);
    if (result) {
      this.product.isInWishlist = true;
      //this.updateLocalStorage(product);
    }
    else {
      this.product.isInWishlist = false;
    }
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  private updateLocalStorage(product: Product): void {
    let products = JSON.parse(localStorage.getItem('products') || '[]') as Product[];
    products = products.map(p => p.id === product.id ? { ...p, isInCart: true } : p);
    localStorage.setItem('products', JSON.stringify(products));
  }

}
