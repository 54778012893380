<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of sliders">
	  <ng-template carouselSlide>
	    <div class="home" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+slider.image+')'}">
	      <div class="container">
	        <div class="row">
	          <div class="col">
	            <div class="slider-contain">
	              <div>
	                <h4>{{slider.title}}</h4>
	                <h1>{{slider.subTitle}}</h1>
	                <a [routerLink]="['/shop/collection/left/sidebar']" 
	                   [queryParams]="{ category: category}" 
	                   class="btn btn-solid" [ngClass]="buttonClass">
	                   {{buttonText}}
	                </a>
	              </div>
				</div>
				<div class="slider">
				<!--   <div *ngFor="let slide of sliders; let i = index" 
					   [class.active]="i === activeSlideIndex" 
					   [class.previous]="i === previousSlideIndex" 
					   class="slide"> -->
					<!-- <img [src]="slide.image" alt="Slide Image"> -->
				 <!--  </div> -->
				</div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </ng-template>
	</ng-container>
</owl-carousel-o>