import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './body/home/home.component';
import { ProductComponent } from './body/product/product.component';
import { FooterComponent } from './footer/footer.component';

@Component({
  selector: 'app-vastra',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    HomeComponent,
    ProductComponent,
    FooterComponent
  ],
  templateUrl: './vastra.component.html',
  styleUrl: './vastra.component.scss'
})
export class VastraComponent {

}
