
<!-- Home slider start-->
<section class="p-0">
    <app-slider 
      [sliders]="sliders" 
      [textClass]="'text-center'"
      [category]="'fashion'"
      [buttonText]="'shop now'"> 
    </app-slider>
  </section>
  <!-- Home slider End -->
