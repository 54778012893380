import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModule } from '../../../home/home.module'; // Import SharedModule

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, HomeModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  sliders: any[] = [
    // Add your slider data here
  ];

  constructor() { }

  ngOnInit(): void {
    // Initialize your slider data here
    this.sliders = [
      {
        title: 'welcome to fashion',
        subTitle: 'TOP fashion',
        image: 'assets/images/slider/1.jpg'
      },
      {
        title: 'welcome to fashion',
        subTitle: 'Trands Now',
        image: 'assets/images/slider/2.jpg'
      }
      // Add more sliders as needed
    ];
  }
}
