import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module'; 

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule,SharedModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  phoneNumber: string = '123-456-7890'; // Replace with your phone number
  isCopied: boolean = false; // Flag to control the visibility of the message

  copyToClipboard() {
    navigator.clipboard.writeText(this.phoneNumber).then(() => {
      // Optionally, you can show a message to the user
      //alert('Phone number copied to clipboard');
      this.isCopied = true; // Set the flag to true when the text is copied
      setTimeout(() => {
        this.isCopied = false; // Hide the message after 1.5 seconds
      }, 1500);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }
constructor() { }

  ngOnInit(): void {
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }
}
