<!-- Old UI -->
<!-- <ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<app-tap-to-top></app-tap-to-top>
<app-layout-box></app-layout-box> -->
<!-- New UI -->
<div class="blur-overlay" [class.active]="isLoading"></div>
<router-outlet></router-outlet>
<!-- Testing -->
 <!-- <app-product-list></app-product-list> -->
<!-- <router-outlet></router-outlet> -->